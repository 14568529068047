"use client"
import { useTheme } from "@mui/material/styles"
import type { ElementContent, Element, Text } from "hast"
import dynamic from "next/dynamic"
import { type FC, memo, useCallback, useMemo } from "react"

import remarkBreaks from "remark-breaks"
import remarkGfm from "remark-gfm"

import type { RichTextProps } from "./RichText.model"
import styles from "./RichText.module.scss"

import { AnimatedText } from "@/components/common/animatedText"
import { Headline } from "@/components/common/headline"
import { Icon } from "@/components/common/icon"
import { Link } from "@/components/common/link"

import { create } from "@/helpers/bem"

const Markdown = dynamic(
  () =>
    import("react-markdown").then((module) => ({ default: module?.default })),
  { ssr: false },
)

const bem = create(styles, "RichText")

// Type guard to check if the node is an Element
function isElement(node: ElementContent): node is Element {
  return node && (node as Element).tagName !== undefined
}

// Type guard to check if a node is a Text node
function isText(node?: ElementContent): node is Text {
  if (!node) return false
  return node && node.type === "text" && "value" in node
}

export const RichText: FC<RichTextProps> = memo(
  ({
    className,
    animated,
    animationProps = {},
    variant = "primary",
    schema = {},
    itemProp,
    children,
    ...props
  }) => {
    const theme = useTheme()
    const palette = theme.palette
    const themeModifier = {
      [palette.mode]: true,
    }
    const baseProps = useMemo(
      () => ({
        animated,
        className: className ?? undefined,
        ...schema,
      }),
      [animated, schema, className],
    )
    const headlineProps = useMemo(
      () => ({
        animationProps,
      }),
      [animationProps],
    )
    const renderAnimation = useCallback(
      (children: any) => {
        if (!animated) return children
        return <AnimatedText {...animationProps}>{children}</AnimatedText>
      },
      [animated, animationProps],
    )
    return (
      <Markdown
        remarkPlugins={[remarkGfm, remarkBreaks]}
        components={{
          h1: (props) => (
            <Headline {...baseProps} {...headlineProps} size="xxl" type="h1">
              {props?.children}
            </Headline>
          ),
          h2: (props) => (
            <Headline {...baseProps} {...headlineProps} size="xl" type="h2">
              {props?.children}
            </Headline>
          ),
          h3: (props) => (
            <Headline {...baseProps} {...headlineProps} size="lg" type="h3">
              {props?.children}
            </Headline>
          ),
          h4: (props) => (
            <Headline {...baseProps} {...headlineProps} size="md" type="h4">
              {props?.children}
            </Headline>
          ),
          h5: (props) => (
            <Headline {...baseProps} {...headlineProps} size="sm" type="h5">
              {props?.children}
            </Headline>
          ),
          h6: (props) => (
            <Headline {...baseProps} {...headlineProps} size="xs" type="h6">
              {props?.children}
            </Headline>
          ),
          p: (props) => (
            <p
              {...baseProps}
              className={bem("p", themeModifier, className ?? undefined)}
              itemProp={itemProp}
            >
              {renderAnimation(props?.children)}
            </p>
          ),
          a: (props) => (
            <Link
              {...baseProps}
              aria-label={props?.children?.toString()}
              href={props?.href ?? "#"}
            >
              {renderAnimation(props?.children)}
            </Link>
          ),
          pre: (props) => (
            <pre
              {...baseProps}
              className={bem("pre", themeModifier, className ?? undefined)}
            >
              {renderAnimation(props?.children)}
            </pre>
          ),
          ul: (props) => (
            <ul
              {...baseProps}
              className={bem("ul", themeModifier, className ?? undefined)}
            >
              {renderAnimation(props?.children)}
            </ul>
          ),
          ol: ({ node }) => (
            <ol
              {...baseProps}
              className={bem("ol", themeModifier, className ?? undefined)}
            >
              {node?.children.map((e: ElementContent, index: number) => {
                if (
                  isElement(e) &&
                  e.children.length > 0 &&
                  isText(e.children[0])
                ) {
                  return (
                    <li
                      key={index}
                      {...baseProps}
                      className={bem(
                        "li",
                        themeModifier,
                        className ?? undefined,
                      )}
                    >
                      <i className={bem("ol__decimal", themeModifier)}>
                        {index + 1}
                      </i>
                      {renderAnimation(<span>{e.children[0].value}</span>)}
                    </li>
                  )
                }
                return null
              })}
            </ol>
          ),
          li: (props) => (
            <li
              {...baseProps}
              className={bem("li", themeModifier, className ?? undefined)}
            >
              <Icon
                className={bem("li__icon")}
                color={palette?.[variant]?.main}
                name="ArrowRight01Icon"
                size={18}
              />
              {renderAnimation(<span>{props?.children}</span>)}
            </li>
          ),
        }}
        {...props}
      >
        {children}
      </Markdown>
    )
  },
)

RichText.displayName = "RichText"
