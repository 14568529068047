import type { Theme, ThemeOptions } from "@mui/material/styles"

import { resolveMediaUrl } from "@/helpers/api-helpers"
import type { UploadFileEntityResponse } from "@/types/generated/strapi/graphql"

export const resolveDarkModeAsset = (
  theme: Theme | ThemeOptions,
  src: UploadFileEntityResponse,
  srcDark?: UploadFileEntityResponse | null,
) => {
  if (srcDark?.data && theme.palette?.mode === "dark")
    return resolveMediaUrl(srcDark?.data?.attributes?.url)
  return resolveMediaUrl(src?.data?.attributes?.url)
}
