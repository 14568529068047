import Image from "next/image"
import type { FC } from "react"

import type { NextImageProps } from "./Image.model"
import styles from "./Image.module.scss"

import { create } from "@/helpers/bem"
import { IS_PRODUCTION } from "@/utils/enviroment"

const bem = create(styles, "Image")

export const NextImage: FC<NextImageProps> = ({
  alt,
  caption,
  containerClassName,
  captionClassName,
  className,
  ...props
}) => {
  const renderImage = () => (
    <Image
      alt={alt ?? ""}
      className={bem("image", undefined, className)}
      unoptimized={!IS_PRODUCTION}
      {...props}
    />
  )
  if (caption) {
    return (
      <figure className={bem(undefined, undefined, containerClassName)}>
        {renderImage()}
        {caption && (
          <figcaption className={bem("caption", undefined, captionClassName)}>
            {caption}
          </figcaption>
        )}
      </figure>
    )
  }
  return renderImage()
}
