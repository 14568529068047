"use client"
import Skeleton from "@mui/material/Skeleton"
import { useTheme } from "@mui/material/styles"
import type { FC, ReactNode, KeyboardEvent } from "react"
import { useCallback } from "react"
import type { WordSet } from "react-bem-helper"

import { Animated } from "../animated"
import { NextImage } from "../image"
import { Link } from "../link"

import type { CardProps } from "./Card.model"

import styles from "./Card.module.scss"

import { create } from "@/helpers/bem"

import BGprimary1 from "@/public/images/card_background_primary_1.webp"
import BGprimary2 from "@/public/images/card_background_primary_2.webp"
import BGprimary3 from "@/public/images/card_background_primary_3.webp"
import BGprimary4 from "@/public/images/card_background_primary_4.webp"

import BGsecondary1 from "@/public/images/card_background_secondary_1.webp"
import BGsecondary2 from "@/public/images/card_background_secondary_2.webp"
import BGsecondary3 from "@/public/images/card_background_secondary_3.webp"
import BGsecondary4 from "@/public/images/card_background_secondary_4.webp"

const bem = create(styles, "Card")

export const Card: FC<CardProps> = ({
  ref,
  variant = "white",
  loading,
  skeletonProps = {},
  highlight,
  gradiant,
  gradiantClassName,
  enableShadow,
  animated = true,
  customAnimation,
  className,
  contentClassName,
  background,
  backgroundProps,
  disabled,
  redirect,
  onClick,
  onKeyDown,
  onMouseEnter,
  onMouseLeave,
  children,
}) => {
  const theme = useTheme()

  const renderSkeleton = useCallback(
    (modifier?: WordSet) =>
      loading && (
        <Skeleton
          className={bem("skeleton", modifier)}
          variant="rectangular"
          {...skeletonProps}
        />
      ),
    [loading, skeletonProps],
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        onClick?.()
      }
      onKeyDown?.(e)
    },
    [onClick, onKeyDown],
  )

  const renderContainer = useCallback(
    (card: ReactNode) => {
      const onClickIsFunction = typeof onClick === "function"
      const isClickable = (onClickIsFunction && !disabled) || !!redirect?.href
      const modifier = {
        [theme.palette.mode]: true,
        [`${theme.palette.mode}--${variant}`]: true,
        "has-highlight": !!highlight,
        "has-gradiant": !!gradiant,
        "has-background": !!background,
        "has-shadow":
          (isClickable && enableShadow === undefined) || !!enableShadow,
        [`has-shadow--${theme.palette.mode}`]:
          (isClickable && enableShadow === undefined) || !!enableShadow,
        "has-animation": animated,
        "is-clickable": isClickable,
      }
      let cardProps = {
        ref,
        className: bem(undefined, modifier, className),
        tabIndex: !disabled && onClickIsFunction ? 0 : -1,
        onClick: !disabled && onClickIsFunction ? onClick : undefined,
        onMouseEnter,
        onMouseLeave,
      }
      if (customAnimation) {
        return (
          <Animated animation={customAnimation} {...cardProps}>
            {renderSkeleton(modifier)}
            {card}
          </Animated>
        )
      }
      return (
        <div {...cardProps} onKeyDown={handleKeyDown}>
          {renderSkeleton(modifier)}
          {card}
        </div>
      )
    },
    [
      theme,
      ref,
      variant,
      highlight,
      gradiant,
      background,
      animated,
      customAnimation,
      className,
      onClick,
      onMouseEnter,
      onMouseLeave,
      redirect?.href,
      disabled,
      enableShadow,
      renderSkeleton,
    ],
  )

  const renderCard = useCallback(
    () =>
      redirect !== undefined ? (
        <Link
          disabled={disabled}
          className={bem(
            "content",
            {
              [`${theme.palette.mode}--${variant}`]: true,
            },
            contentClassName,
          )}
          {...redirect}
        >
          {children}
        </Link>
      ) : (
        <div className={bem("content", undefined, contentClassName)}>
          {children}
        </div>
      ),
    [
      contentClassName,
      theme.palette.mode,
      variant,
      redirect,
      disabled,
      children,
    ],
  )

  const renderBackground = useCallback(() => {
    let backgroundSrc = BGprimary1
    if (variant === "secondary") {
      switch (background) {
        case 2:
          backgroundSrc = BGsecondary2
          break
        case 3:
          backgroundSrc = BGsecondary3
          break
        case 4:
          backgroundSrc = BGsecondary4
          break
        default:
          backgroundSrc = BGsecondary1
      }
    }
    switch (background) {
      case 2:
        backgroundSrc = BGprimary2
        break
      case 3:
        backgroundSrc = BGprimary3
        break
      case 4:
        backgroundSrc = BGprimary4
        break
    }
    return (
      <NextImage
        fill
        alt="prokodo - background image"
        placeholder="blur"
        src={typeof background === "string" ? background : backgroundSrc}
        className={bem("background", {
          [theme.palette.mode]: true,
        })}
        {...backgroundProps}
      />
    )
  }, [theme, variant, background, backgroundProps])

  if (gradiant) {
    return renderContainer(
      <div
        className={bem("gradiant", undefined, gradiantClassName)}
        style={{
          background:
            variant !== "inherit" &&
            variant !== "white" &&
            (theme.gradiant as any)?.[variant],
        }}
      >
        {renderCard()}
      </div>,
    )
  }
  if (background) {
    return renderContainer(
      <>
        {renderCard()}
        {renderBackground()}
      </>,
    )
  }
  return renderContainer(renderCard())
}
